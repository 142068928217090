import { Layout, SEO } from "../components"

import React from "react"
import styled from "styled-components"

const NotFoundPage = () => (
  <Layout>
    <Wrapper>
      <SEO title="404: Not found" />
      <h1>404: page introuvable</h1>
      <p>Vous venez de prendre un chemin qui n'existe pas...</p>
    </Wrapper>
  </Layout>
)

const Wrapper = styled.main`
  margin-top: 4rem;
  background: var(--clr-grey-9);
  min-height: calc(100vh - 13rem);
  display: grid;
  place-items: center;
  div {
    text-align: center;
    color: var(--clr-white);
  }
  h1 {
    font-size: 2rem;
    color: var(--clr-primary-5);
    margin-bottom: 1.5rem;
  }
  @media (min-width: 800px) {
    h1 {
      font-size: 4rem;
    }
  }
`

export default NotFoundPage
